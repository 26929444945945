<template>
    <b-overlay :show="loading">
        <b-row class="d-flex justify-content-center">
            <b-col sm="12" md="10" lg="8">
                <h3 class="mb-2">Input Pembayaran Pembelian</h3>
                <!-- Informasi Umum Barang -->
                <b-card-actions :title="`Rincian Barang (${pembelian.rincian.length})`" action-collapse>
                    <div v-if="pembelian.rincian && pembelian.rincian.length > 0">
                        <b-table responsive :fields="fields" :items="pembelian.rincian">
                            <template #cell(barang)="{item}">
                                <span v-if="item.barang">
                                    {{item.barang.nama}}
                                </span>
                                <i v-else class="text-danger">Barang tidak ada.</i>
                            </template>
                            <template #cell(diskon)="{item}">
                                {{formatRupiah(item.diskon)}}
                            </template>
                            <template #cell(harga_beli)="{item}">
                                {{formatRupiah(item.harga_beli)}}
                            </template>
                        </b-table>
                        <div class="d-flex justify-content-end my-2">
                            <table>
                                <tr>
                                    <td>Total Pembelian</td>
                                    <td>:</td>
                                    <th>
                                        <h4>
                                            Rp {{ pembelian.informasi ? formatRupiah(pembelian.informasi.total_pembelian) : 0 }}
                                        </h4>
                                    </th>
                                </tr>
                                <tr>
                                    <td>Total Pembayaran</td>
                                    <td>:</td>
                                    <th><h4>Rp {{ pembelian.informasi ? formatRupiah(pembelian.informasi.total_bayar) : 0 }}</h4></th>
                                </tr>
                                <tr>
                                    <td>Hutang</td>
                                    <td>:</td>
                                    <th><h4>Rp {{ pembelian.informasi ? formatRupiah(pembelian.informasi.hutang) : 0 }}</h4></th>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="alert alert-danger p-2" v-else>Tidak ada barang.</div>
                </b-card-actions>
                <!-- Form Bayar -->
                <b-card-actions title="Form Input Pembayaran" action-collapse>
                    <b-form-group class="mb-2">
                        <h5>Metode Bayar</h5>
                        <v-select :options="metodeOptions" v-model="form.metode" label="text" :reduce="option => option.value"></v-select>
                    </b-form-group>
                    <b-form-group class="mb-2">
                        <h5>Nominal</h5>
                        <b-form-input v-model="form.nominal" @keyup="doFormatRupiah"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2">
                        <h5>Tanggal Pembayaran</h5>
                        <b-form-input v-model="form.tgl_pembayaran" type="date"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="form.metode == 3">
                        <h5>No. Giro</h5>
                        <b-form-input v-model="form.no_giro" type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="form.metode == 3">
                        <h5>Tanggal Giro</h5>
                        <b-form-input v-model="form.tgl_giro" type="date"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="form.metode == 3">
                        <h5>Tanggal Bayar Giro</h5>
                        <b-form-input v-model="form.tgl_bayar" type="date"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="[2,3].includes(form.metode)">
                        <h5>No. Rekening</h5>
                        <b-form-input v-model="form.norek" type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="[2,3].includes(form.metode)">
                        <h5>Pemilik Rekening</h5>
                        <b-form-input v-model="form.pemilik" type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2" v-if="[2,3].includes(form.metode)">
                        <h5>Bank</h5>
                        <b-form-input v-model="form.bank" type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-2">
                        <h5>Pilih Akun</h5>
                        <v-select :options="akuns" v-model="akun" label="text" class="mb-1"></v-select>
                    </b-form-group>
                    <b-form-group class="mb-2">
                        <h5>Pilih Kas</h5>
                        <v-select :options="listKas" v-model="kas" label="text" class="mb-1"></v-select>
                        <i class="text-danger" v-if="invalidKas">Saldo Kas kurang dari nominal</i>
                    </b-form-group>

                    <div class="d-flex justify-content-end">
                        <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Simpan</b-button>
                    </div>
                </b-card-actions>
                <!-- / -->
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BTable, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import service from '@/services'
export default {
    components: {
        BOverlay, BRow, BCol, BTable, BCardActions, vSelect, BFormGroup, BFormInput, BButton
    },
    watch: {
        akun(akun) {
            if(akun && akun.kategori) {
                const isKas = akun.kategori && akun.kategori.kategori == 'Kas & Bank'
                this.akunHasKas = isKas ? true : false
            }
        }
    },
    computed: {
        invalidAkun() {
            if(!this.akun) return false
            const akun = this.akuns.find(item => item.value == this.akun.value)
            
            if(!akun) return false

            return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < this.unFormatRupiah(this.form.nominal))
        },
        invalidKas() {
            if(!this.kas) return false
            const kas = this.listKas.find(item => item.value == this.kas.value)
            if(!kas) return false

            return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < this.unFormatRupiah(this.form.nominal))
        },
        isValidForm() {

            if(!this.akun || !this.kas) {
                return false
            }
            if(this.invalidKas) {
                return false
            }

            if(!this.form.metode) {
                return false
            }

            const isTunai = this.form.metode == 1
            const isTransfer = this.form.metode == 2
            const isGiro = this.form.metode == 3
            const isNotaPutih = this.form.metode == 4

            if(isTunai || isNotaPutih) {
                if(!this.form.tgl_pembayaran || this.form.nominal < 1 || !this.akun) {
                    return false
                }

                return true
            }
            if(isTransfer) {
                if(!this.form.tgl_pembayaran || this.form.nominal < 1 || !this.akun || !this.form.norek || !this.form.pemilik || !this.form.bank) {
                    return false
                }
                
                return true
            }
            if(isGiro) {
                if(!this.form.tgl_pembayaran || this.form.nominal < 1 || !this.akun || !this.form.no_giro || !this.form.tgl_bayar || !this.form.tgl_giro || !this.form.pemilik || !this.form.bank) {
                    return false
                }

                return true
            }

            return false

        }
    },
    data: () => ({
        akunHasKas: false,
        form: {
            metode: '',
            nominal: 0,
            tgl_pembayaran: '',
            norek: '',
            pemilik: '',
            bank: '-',
            no_giro: '',
            tgl_bayar: '',
            tgl_giro: '',
            id_akun: null,
            id_kas: null
        },
        metodeOptions: [
            {
                text: 'Tunai',
                value: 1
            },
            {
                text: 'Transfer',
                value: 2
            },
            {
                text: 'Giro',
                value: 3
            },
            {
                text: 'Nota Putih',
                value: 4
            }
        ],
        loading: false,
        pembelian: {},
        fields: [
        {
                key: 'barang',
                label: 'Nama Barang'
            },
            {
                key: 'qty',
                label: 'Qty'
            },
            {
                key: 'diskon',
                label: 'Diskon'
            },
            {
                key: 'harga_beli',
                label: 'Harga Beli'
            }
        ],
        hasPermissionPay: false,
        akuns: [],
        listKas: [],
        akun: null, // for selected akun
        kas: null // for selected kas
    }),
    methods: {
        doFormatRupiah() {
            this.form.nominal = this.formatRupiah(this.form.nominal)
        },
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form pembayaran!'
                })
                return false
            }

            const payload = Object.assign({}, this.form)
            payload.id_pembelian = this.$route.params.id
            payload.nominal = this.unFormatRupiah(payload.nominal)
            payload.id_akun = this.akun.value
            if(this.akunHasKas && !this.kas) {
                this.displayError({
                    message: 'Harap pilih Kas!'
                })
                return false
            }
            if(this.akunHasKas) payload.id_kas = this.kas.value

            try {
                this.loading = true
                const config = {
                    url: `/input/pembelian-bayar`,
                    method: 'post',
                    data: [payload],
                    headers: {
                        Authorization: `${localStorage.tokenType} ${localStorage.token}`
                    }
                }
                await service.sendRequest(config)
                await this.$store.dispatch('pembelian/getData')
                this.loading = false
                this.displaySuccess({
                    message: 'Pembayaran Berhasil'
                })
                setTimeout(() => {this.$router.push(`/pembelian/detail/${this.$route.params.id}`)}, 500)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        getPembelian() {
            const pembelians = this.$store.state.pembelian.datas
            if(!pembelians) {
                this.$router.push('/pembelian')
            }

            const pembelian = pembelians.find(item => item.id == this.$route.params.id)
            if(!pembelian) {
                this.$router.push('/pembelian')
            }

            this.pembelian = pembelian
        },
        async checkPermissionPay() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            const listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionPay = listStatus.some(status => status.data_status == 2)
            this.hasPermissionPay = hasPermissionPay
            if(!this.hasPermissionPay) {
                this.$router.push(`/pembelian/detail/${this.$route.params.id}`)
            }
        },
        async getAkun() {
            const akuns = await this.$store.dispatch('akun/getData')
            akuns.map(akun => {
                if( [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis) ) {
                    this.akuns.push({
                        text: `${akun.nama} -> Rp ${this.formatRupiah(akun.saldo)}`,
                        value: akun.id,
                        saldo: akun.saldo,
                        kategori: akun.kategori
                    })
                }
            })
        },
        async getKas() {
            const listKas = await this.$store.dispatch('kas/getData')
            listKas.map(kas => {
                this.listKas.push({
                    text: `${kas.nama} -> Rp ${this.formatRupiah(kas.saldo)}`,
                    saldo: kas.saldo,
                    value: kas.id
                })
            })
        }
    },
    async created() {
        // check if user has permission to pay
        await this.checkPermissionPay()
        await this.getAkun()
        await this.getKas()
        this.form.tgl_pembayaran = this.getCurrentDate()
        this.getPembelian()
    }
}
</script>